import { useMsal } from '@azure/msal-react';
import React from 'react';
import { Button } from 'react-bootstrap';

export const LogoutButton = ({ variant }) => {
    const { instance } = useMsal();
    
    const onLogoutClick = () => {
        instance.logoutRedirect();
    }

    return (
        <Button
            variant={variant}
            onClick={onLogoutClick}
        >
            Log out
        </Button>
    );
}