import React, { Fragment, useContext, useEffect, useState } from 'react';
import { Card, Row, Col, Table, Button, Spinner, Form, ButtonGroup, Modal, ListGroup } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSync, faSort, faSortUp, faSortDown, faDownload, faPlusCircle, faCheckCircle, faCircle } from '@fortawesome/pro-solid-svg-icons';
import { useDebouncedCallback } from 'use-debounce';
import { packageWorkbook } from '../../services/reports';

import { Checkbox } from '../checkbox';

import './index.scss';
import { UserProfileContext } from '../../userProfileContext';
import { getUserProfileDataGridSettings, upsertUserProfileDataGridSettings } from '../../services/user-profiles';
import { faColumns3, faDoNotEnter } from '@fortawesome/pro-duotone-svg-icons';
import WhatsThis from '../whats-this';

export const DataGridFilterTypes = {
    TEXT: 'text',
    SELECT: 'select',
    DATE: 'date',
    DATE_RANGE: 'date-range',
    NUMBER: 'number',
    NUMBER_RANGE: 'number-range',
    BOOLEAN: 'boolean',
    CHECKBOX: 'checkbox',
    RADIO: 'radio',
    ARRAY_ISEMPTY: 'array-empty',
    CUSTOM: 'custom',
};

export const customColumnTypes = {
    required: { key: 'required', label: 'Required', },
    default: { key: 'default', label: 'Default', },
    optional: { key: 'optional', label: 'Optional', },
    hidden: { key: 'hidden', label: 'Hidden', },
    custom: { key: 'custom', label: 'Custom', },
};

const defaultDataGridSettings = {
    itemType: "dataGridSettings",
    customColumnTypes: {},
};

export const DataGrid = ({ id, dataSet, customTools, tableProps, exportConfiguration, onRefresh, onAddNew, onRowClick, onServerFilterChanged, onSortChanged, loading, itemsSelected, selectedActions, noResultsText, hideNoResultsText, hideHeader, disableSort, stickyHeader, formatDetailsRow, lockPaidFeature }) => {
    const { userProfile } = useContext(UserProfileContext);
    const [userProfileDataGridSettings, setProfileUserDataGridSettings] = useState({...defaultDataGridSettings});
    const [customizeColumns, setCustomizeColumns] = useState({ showModal: false });
    const [sortField, setSortField] = useState(dataSet?.defaultSort?.field);
    const [sortDirection, setSortDirection] = useState(dataSet?.defaultSort?.direction ?? 1);
    const [filter, setFilter] = useState({});
    const [filterServer, setFilterServer] = useState({});
    const [selectedFacets, setSelectedFacets] = useState({});
    const [filteredItems, setFilteredItems] = useState([]);
    const [expandedDetails, setExpandedDetails] = useState({});
    const [groupToggleMap, setGroupToggleMap] = useState({});

    useEffect(() => {
        if (!!userProfile.id && !!id) {
            getUserProfileDataGridSettings({ userProfileId: userProfile.id, dataGridId: id })
            .then(r => setProfileUserDataGridSettings(r.data))
            .catch(e => {
                setProfileUserDataGridSettings({
                    ...defaultDataGridSettings,
                    userProfileId: userProfile.id,
                    id: id,
                });
            });
        }
    }, [userProfile.id, id]);

    useEffect(() => {
        if (dataSet) {
            const filterFn = i => {
                return dataSet.fields.map(f => f.name).every(n => !(filter[n]) || (i[n] && i[n].toLowerCase().indexOf(filter[n].toLowerCase()) > -1));
            }
            const facetFn = i => {
                return Object.keys(selectedFacets).every(k => {
                    const facet = dataSet.facets.find(f => f.key === k);
                    if (facet) {
                        return facet.filter(i, selectedFacets[k]);
                    }
                    return true;
                });
            }

            setFilteredItems(
                dataSet.data.filter(i => {
                    if (filterFn(i)) {
                        if (Object.keys(selectedFacets).length === 0) {
                            return true;
                        }
                        return facetFn(i);
                    }
                    return false;
                })
            );
        }
    }, [filter, selectedFacets, dataSet]);

    // useEffect(() => {
    //     if (dataSet?.defaultSort?.field) {
    //         setSortField(dataSet.defaultSort.field);
    //         setSortDirection(dataSet.defaultSort?.direction ?? 1);
    //     }
    // }, [dataSet])

    const sortItems = (c, p) => {
        if (c[sortField] === p[sortField]) {
            return c.id > p.id ? sortDirection : (sortDirection * -1);
        }
        return c[sortField] > p[sortField] ? sortDirection : (sortDirection * -1);
    }

    const sortIcon = (n) => {
        if (disableSort) {
            return null;
        }
        let icon = faSort;
        if (sortField === n) {
            icon = sortDirection < 0 ? faSortDown : faSortUp;
        }
        return (
            <FontAwesomeIcon
                className={`sort${sortField === n ? ' ' : ' in'}active`}
                icon={icon}
                onClick={() => onSortClick(n)}
            />
        );
    }

    const onSortClick = (n) => {
        if (typeof(onSortChanged) === 'function') {
            if (sortField === n) {
                onSortChanged({ field: n, direction: sortDirection > 0 ? -1 : 1 });
            } else {
                onSortChanged({ field: n, direction: 1 });
            }
        } else {
            if (sortField === n) {
                setSortDirection(sortDirection * -1);
            } else {
                setSortField(n);
                setSortDirection(1);
            }
        }
    }

    const onFilterChange = (n, v) => {
        setFilter({
            ...filter,
            [n]: v
        });
    }

    const onFilterServerChange = (n, v, op) => {
        setFilterServer(f => {
            const s = {
                ...f,
                [n]: v,
                [`${n}_op`]: op
            };
            fireServerFilterChanged(s);
            return s;
        });
    }
    const fireServerFilterChanged = useDebouncedCallback((filters) => {
        onServerFilterChanged({
            filters: {...filters} 
        });
    }, 1000);

    const onClickExport = () => {
        packageWorkbook({
            workbookId: exportConfiguration.workbookId,
            packageData: {
                ...exportConfiguration.packageData,
                sheets: [
                    {
                        ...exportConfiguration.packageData.sheets[0],
                        rows: filteredItems.sort(sortItems).map((item, itemIndex) => {
                            return {
                                values: dataSet.fields.filter(f => !f.hideColumn).reduce((a, field, fieldIndex) => {
                                    return {
                                        ...a,
                                        [field.header]: {
                                            value: typeof(field.exportFormat) === 'function'
                                                ? field.exportFormat(item, itemIndex, fieldIndex)
                                                : typeof(field.format) === 'function'
                                                    ? field.format(item, itemIndex, fieldIndex)
                                                    : item[field.name],
                                            formatString: field.exportFormatString ?? ''
                                        }
                                    }
                                }, {})
                            };
                        })
                    }
                ]
            }
        });
    }

    const onClickRefresh = () => {
        onRefresh();
    }

    const formatItem = (field, item, itemIndex, fieldIndex) => {
        if (typeof (field.format) === 'function') {
            return field.format(item, itemIndex, fieldIndex);
        } else {
            return (
                <span>{item[field.name]}</span>
            );
        }
    }

    const onToggleFacetValue = (key, value, on) => {
        setSelectedFacets(s => {
            if (on) {
                if (s[key] && s[key].length > 0) {
                    return {
                        ...s,
                        [key]: [
                            ...s[key],
                            value
                        ]
                    };
                }
                return {
                    ...s,
                    [key]: [value]
                }
            }
            if (s[key].length === 1) {
                delete s[key];
                return s;
            }
            return {
                ...s,
                [key]: s[key].filter(v => v !== value)
            };
        });
    }

    const filterField = (field, index, fieldArray) => {
        if (field.hideColumn === true) {
            return false;
        }
        const customColumnType = userProfileDataGridSettings?.customColumnTypes?.[field.name] ?? field.customColumnType;
        if ([customColumnTypes.hidden.key,customColumnTypes.optional.key].includes(customColumnType)) {
            return false;
        }
        return true;
    }

    const mapItemsToRows = (item, itemIndex) => {
        return (
            <Fragment key={itemIndex}>
                <tr onClick={() => { if (typeof(onRowClick) === 'function') onRowClick(item); }}>
                    {dataSet.fields.filter(filterField).map((field, fieldIndex) => {
                        if (field.isDetailsToggle) {
                            return (
                                <td key={fieldIndex} style={field.style}>
                                    {typeof(field.disabled) === 'function' && field.disabled(item) ? (
                                        <div>{field.disabledMessage}</div>
                                    ) : (
                                        <Form.Check
                                            checked={expandedDetails[itemIndex] || false}
                                            onChange={() => setExpandedDetails(s => {
                                                return {
                                                    ...s,
                                                    [itemIndex]: s[itemIndex] ? false : true
                                                };
                                            })}
                                            type="switch"
                                            label=""
                                            id={`switch-details-${itemIndex}`}
                                        />
                                    )}
                                    {typeof(field.hintText) === 'function' ? (
                                        <div>{field.hintText(item)}</div>
                                    ): null}
                                </td>
                            );
                        }
                        return (
                            <td
                                key={fieldIndex}
                                className={lockPaidFeature ? 'blur': ''}
                                style={field.style}
                            >
                                {formatItem(field, item, itemIndex, fieldIndex)}
                            </td>
                        );
                    })}
                </tr>
                {expandedDetails[itemIndex] && (
                    <tr>
                        {formatDetailsRow(item, itemIndex)}
                    </tr>
                )}
            </Fragment>
        );
    }

    const onClickCustomizeColumns = () => {
        setCustomizeColumns({ showModal: true });
    }

    const onCancelCustomizeColumns = () => {
        setCustomizeColumns({ showModal: false });
    }

    const onConfirmCustomizeColumns = () => {
        upsertUserProfileDataGridSettings({
            userProfileId: userProfile.id,
            dataGridId: id,
            dataGridSettings: {
                ...userProfileDataGridSettings,
                customColumnTypes: userProfileDataGridSettings.customColumnTypes,
            }
        }).then(r => {
            setProfileUserDataGridSettings(r.data);
        }).finally(() => {
            setCustomizeColumns({ showModal: false });
        });
    }

    if (dataSet === null || dataSet === undefined) {
        return (<div>no dataset is configured</div>);
    }
    
    return (
        <Card className="data-grid">
            {(dataSet.title || exportConfiguration?.title || typeof (onRefresh) === 'function' || typeof (onAddNew) === 'function') && (
                <Card.Header>
                    <Row>
                        {dataSet.title && (
                            <Col>
                                <Card.Title>
                                    {dataSet.title}
                                    {dataSet.subtitle && (
                                        <div style={{ fontSize: '1rem', marginTop: '0.5rem', fontWeight: 'normal' }}>{dataSet.subtitle}</div>
                                    )}
                                </Card.Title>
                            </Col>
                        )}
                        {(customTools && (
                            <Col style={{ textAlign: 'end' }}>
                                {customTools}
                            </Col>
                        )) || (
                            <Col style={{ textAlign: 'end' }}>
                                <ButtonGroup>
                                    {!!id && (
                                        <Button
                                            title={'Customize Columns'}
                                            variant="primary"
                                            onClick={onClickCustomizeColumns}
                                        >
                                            <FontAwesomeIcon icon={faColumns3} />
                                        </Button>
                                    )}
                                    {exportConfiguration?.title &&
                                        <Button
                                            title={lockPaidFeature ? 'Export is locked' : `Export ${exportConfiguration.title} to Excel`}
                                            variant="primary"
                                            onClick={onClickExport}
                                            disabled={lockPaidFeature}
                                        >
                                            <FontAwesomeIcon icon={faDownload} />
                                        </Button>
                                    }
                                    {typeof (onRefresh) === 'function' &&
                                        <Button
                                            title="Refresh"
                                            variant="primary"
                                            onClick={onClickRefresh}
                                        >
                                            <FontAwesomeIcon icon={faSync} />
                                        </Button>
                                    }
                                    {typeof (onAddNew) === 'function' &&
                                        <Button
                                            title="Add New"
                                            variant="primary"
                                            onClick={onAddNew}
                                            data-touranchorelement='dataGridAddNew'
                                            data-touranchorposition='left'
                                        >
                                            <FontAwesomeIcon icon={faPlusCircle} />{' Add New'}
                                        </Button>
                                    }
                                </ButtonGroup>
                                <Modal show={customizeColumns.showModal} onHide={onCancelCustomizeColumns}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Customize Columns</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <ListGroup>
                                            {dataSet.fields.filter(f => !f.hideColumn).map((field, index) => {
                                                const checked = field.customColumnType === customColumnTypes.required.key
                                                    ? true
                                                    : !!userProfileDataGridSettings?.customColumnTypes?.[field.name]
                                                        ? userProfileDataGridSettings?.customColumnTypes?.[field.name] === customColumnTypes.default.key
                                                        : field.customColumnType === customColumnTypes.default.key;
                                                return (
                                                    <ListGroup.Item key={index}>
                                                        <Checkbox
                                                            disabled={field.customColumnType === customColumnTypes.required.key}
                                                            checked={checked}
                                                            onChange={e => setProfileUserDataGridSettings(s => {
                                                                return {
                                                                    ...s,
                                                                    customColumnTypes: {
                                                                        ...s.customColumnTypes,
                                                                        [field.name]: e.target.checked ? customColumnTypes.default.key : customColumnTypes.optional.key
                                                                    }
                                                                };
                                                            })}
                                                            label={field.header}
                                                        />
                                                    </ListGroup.Item>
                                                );
                                            })}
                                        </ListGroup>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button variant="secondary" onClick={onCancelCustomizeColumns}>
                                            Cancel
                                        </Button>
                                        <Button variant="primary" onClick={onConfirmCustomizeColumns}>
                                            Save Changes
                                        </Button>
                                    </Modal.Footer>
                                </Modal>
                            </Col>
                        )}
                    </Row>
                </Card.Header>
            )}
            <Card.Body>
                {!hideHeader && (
                    <Row>
                        <Col>
                            {dataSet.fields.filter(filterField).some(f => f.filter)
                                ? `${filteredItems.length} of ${dataSet.data.length} items displayed`
                                : `${dataSet.data.length} items`
                            }
                        </Col>
                        {typeof(selectedActions) === 'function' && (
                            <Col
                                sm={2}
                                style={{ textAlign: 'end'}}
                            >
                                {selectedActions()}
                            </Col>
                        )}
                    </Row>
                )}
                <Row>
                    {dataSet.facets?.length > 0 && (
                        <Col sm={2}>
                            {dataSet.facets.map((facet, facetIndex) => (
                                <div key={facetIndex}>
                                    <div>{facet.label}</div>
                                    {facet.values.map((facetValue, facetValueIndex) => (
                                        <div key={facetValueIndex}>
                                            <Checkbox
                                                value={selectedFacets[facet.key]?.includes(facetValue.value) || false}
                                                onChange={e => onToggleFacetValue(facet.key, facetValue.value, e.target.checked)}
                                                label={`${facetValue.label} (${facetValue.count})`}
                                            />
                                        </div>
                                    ))}
                                </div>
                            ))}
                        </Col>
                    )}
                    <Col>
                        <Table
                            bordered
                            hover
                            style={{ fontSize: '0.9rem' }}
                            {...tableProps}
                        >
                            <thead className={stickyHeader ? 'sticky' : ''}>
                                <tr>
                                    {dataSet.fields.filter(filterField).map((field, index) => {
                                        return (
                                            <th
                                                key={index}
                                                style={field.style || {}}
                                                className="dg-header"
                                            >
                                                {field.formatHeader && typeof(field.formatHeader) === 'function' ? field.formatHeader(field.header) : field.header}
                                                {field.disableSort ? null : sortIcon(field.name)}
                                                {field.whatsThis && (
                                                    <WhatsThis id={`dg-ch-wt-${field.name}`} title={field.header} content={field.whatsThis} />
                                                )}
                                            </th>
                                        );
                                    })}
                                </tr>
                                {(dataSet.fields.filter(filterField).some(f => f.filter || f.filterServer) || dataSet.fields.some(f => typeof(f.selectAll) === 'function')) && (
                                    <tr>
                                        {dataSet.fields.filter(filterField).map((field, index) => {
                                            return (
                                                <th
                                                    key={index}
                                                    className="dg-filter"
                                                >
                                                    {field.filter &&
                                                        <Form.Control
                                                            style={{ fontSize: 'small' }}
                                                            autoComplete='off'
                                                            placeholder={`filter ${field.header}...`}
                                                            value={filter[field.name] || ''}
                                                            onChange={(e) => onFilterChange(field.name, e.target.value)}
                                                        />
                                                    }
                                                    {field.filterServer === DataGridFilterTypes.BOOLEAN
                                                        ? <Form.Control
                                                            as="select"
                                                            value={filterServer[field.name] || ''}
                                                            onChange={e => onFilterServerChange(field.name, e.target.value)}
                                                        >
                                                            <option value="">All</option>
                                                            <option value="true">Yes</option>
                                                            <option value="false">No</option>
                                                        </Form.Control>
                                                        : field.filterServer === DataGridFilterTypes.ARRAY_ISEMPTY
                                                            ? <Form.Control
                                                                    as="select"
                                                                    value={filterServer[field.name] || ''}
                                                                    onChange={e => onFilterServerChange(field.name, e.target.value)}
                                                                >
                                                                    <option value="">All</option>
                                                                    <option value="true">Zero</option>
                                                                    <option value="false">One or more</option>
                                                                </Form.Control>
                                                            : field.filterServer
                                                                ? <>
                                                                    <Form.Control
                                                                        style={{ fontSize: 'small' }}
                                                                        className="server-filter"
                                                                        autoComplete='off'
                                                                        placeholder={`filter ${field.header}...`}
                                                                        value={filterServer[field.name] || ''}
                                                                        onChange={(e) => onFilterServerChange(field.name, e.target.value)}
                                                                    />
                                                                    {!filterServer[field.name] ? (
                                                                        null
                                                                    ) : filterServer[`${field.name}_op`] === 'ne' ? (
                                                                        <FontAwesomeIcon
                                                                            className='filter-icon danger'
                                                                            icon={faDoNotEnter}
                                                                            onClick={() => onFilterServerChange(field.name, filterServer[field.name], 'eq')}
                                                                        />
                                                                    ) : (
                                                                        <FontAwesomeIcon
                                                                            className='filter-icon success'
                                                                            icon={faCheckCircle}
                                                                            onClick={() => onFilterServerChange(field.name, filterServer[field.name], 'ne')}
                                                                        />
                                                                    )}
                                                                </>
                                                                : null
                                                    }
                                                    {typeof(field.selectAll) === 'function' && (
                                                        <div
                                                            style={{ textAlign: 'center', fontSize: 'x-large' }}
                                                            onClick={field.selectAll}
                                                        >
                                                            {(itemsSelected > 0 && (
                                                                <FontAwesomeIcon
                                                                    style={{ color: '#4FC0B0' }}
                                                                    icon={faCheckCircle}
                                                                />
                                                            )) || (
                                                                <FontAwesomeIcon
                                                                    style={{ color: '#9faeb5' }}
                                                                    icon={faCircle}
                                                                />
                                                            )}
                                                        </div>
                                                    )}
                                                </th>
                                            );
                                        })}
                                    </tr>
                                )}
                            </thead>
                            <tbody>
                                {loading &&
                                    <tr>
                                        <td
                                            colSpan={dataSet.fields.filter(filterField).length}
                                            className="noresults"
                                        >
                                            <Spinner variant="success" animation="border" />
                                        </td>
                                    </tr>
                                }
                                {!loading && filteredItems.length === 0 && !hideNoResultsText && (
                                    <tr>
                                        <td
                                            colSpan={dataSet.fields.filter(filterField).length}
                                            className="noresults"
                                        >
                                            {noResultsText ?? 'No results found'}
                                        </td>
                                    </tr>
                                )}
                                {!loading && (
                                    <>
                                        {dataSet.groupBy?.key
                                            ? (
                                                <>
                                                    {dataSet.groupBy.values.map((groupValue, groupIndex) => (
                                                        <Fragment key={groupIndex}>
                                                            <tr>
                                                                <td
                                                                    style={{ cursor: 'pointer' }}
                                                                    onClick={() => setGroupToggleMap(s => { return { ...s, [groupValue]: s[groupValue] ? false : true }; })}
                                                                    colSpan={dataSet.fields.length}
                                                                >
                                                                    <b>{groupValue} ({dataSet.data.filter(d => d[dataSet.groupBy.key] === groupValue).length})</b>
                                                                </td>
                                                            </tr>
                                                            {groupToggleMap[groupValue] && filteredItems.filter(d => d[dataSet.groupBy.key] === groupValue).sort(sortItems).map(mapItemsToRows)}
                                                        </Fragment>
                                                    ))}
                                                </>
                                            )
                                            : filteredItems.sort(sortItems).map(mapItemsToRows)
                                        }
                                    </>
                                )}
                            </tbody>
                        </Table>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
    );
}