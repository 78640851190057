import React, { useContext, useEffect, useState } from 'react';
import { MessageBubble } from './message-bubble';
import { Button, Form } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './chat-conversation.scss';
import { ChatContext } from '../../chatContext';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExternalLinkAlt } from '@fortawesome/pro-solid-svg-icons';

export const ChatConversation = ({ title, conversationProxy, myIdentity, canInitiate, standalone }) =>  {
  const { parseConversationUniqueName } = useContext(ChatContext);
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const [loadingState, setLoadingState] = useState('');
  const [participants, setParticipants] = useState({});

  const textAreaRef = React.createRef();
  const latestMessageRef = React.createRef();

  useEffect(() => {
    const messageAdded = m => {
      if (m.conversation.uniqueName === conversationProxy?.uniqueName) {
        setMessages(p => [...p, m]);
        if (conversationProxy) {
          conversationProxy.updateLastReadMessageIndex(m.index);
          conversationProxy.setAllMessagesRead();
        }
      }
    };

    if (conversationProxy) {
      conversationProxy.getMessages()
      .then(messagePaginator => {
        setMessages([...messagePaginator.items]);
        conversationProxy.setAllMessagesRead();
        setLoadingState('ready');
      })
      .catch(err => {
        console.error("Couldn't fetch messages IMPLEMENT RETRY", err);
        setLoadingState('failed');
      });
      conversationProxy.getParticipants()
      .then(participants => {
        participants.forEach(participant => {
          participant.getUser()
          .then(user => {
            setParticipants(prevState => {
              return {...prevState, [participant.identity]: user };
            });
            user.on('updated', event => {
              setParticipants(prevState => {
                return {...prevState, [event.user.identity]: event.user };
              });
            });
          });
        });
      });
      conversationProxy.on('messageAdded', messageAdded);
    }

    return (() => {
      if (conversationProxy) {
        conversationProxy.off('messageAdded', messageAdded);
      }
      setMessages([]);
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [conversationProxy, conversationProxy.uniqueName]);

  const popOut = (e) => {
    const { rfpId, venueId } = parseConversationUniqueName(conversationProxy.uniqueName);
    window.open(`/#/chat/${rfpId}${venueId ? `/${venueId}` : ''}`, conversationProxy.uniqueName, 'width=430,height=680,menubar=no,status=no');
  }

  const onMessageKeyDown = event => {
    if (event.keyCode === 13 && event.ctrlKey === true && newMessage.trim().length > 0) {
      sendMessage(event);
    }
  }

  const sendMessage = event => {
    event.preventDefault();
    conversationProxy.sendMessage(newMessage).then(index => conversationProxy.updateLastReadMessageIndex(index));
    setNewMessage('');
    if (textAreaRef.current) {
      textAreaRef.current.focus();
    }
    window.analytics.track('Chat Message Sent');
  };

  return (
    <div id="chat-conversation-container">
      {title && (
        <div className="chat-conversation-title">{title}</div>
      )}
      {!standalone && (
        <Button
          className="chat-conversation-popout"
          title="Open in new window"
          variant="outline-info"
          onClick={popOut}
        >
            <FontAwesomeIcon icon={faExternalLinkAlt} />
        </Button>
      )}
      <div className="conversation-messages">
        {(messages.length > 0 && (
          <div id="messages">
            {messages.map((m, i) => {
              const isLatest = i === messages.length - 1;
              const direction = (m.author === myIdentity) ? "outgoing" : "incoming";
              return (
                  <MessageBubble
                    key={m.index}
                    direction={direction}
                    message={m}
                    author={participants[m.author]}
                    itemRef={isLatest ? latestMessageRef : null}
                  />
                );
            })}
          </div>
        )) || (!canInitiate && (
          <div className="chat-empty-message">
            <p>The planner has asked not to be contacted via chat, but may start a thread as needed.</p>
            <p>If a planner starts a chat conversation, we will notify you and can respond here.</p>
            <p>You can update your notification settings any time in your <Link to="/myprofile">Profile</Link> page.</p>
          </div>
        ))}
      </div>
      <div className="conversation-tools">
        <div className="conversation-tools-header">
          Type your message below (click Send or use Ctrl+Enter to send):
        </div>
        <Form.Control
          ref={textAreaRef}
          className="chatMessageInput"
          as="textarea"
          value={newMessage}
          onChange={e => setNewMessage(e.target.value)}
          onKeyDown={onMessageKeyDown}
          onFocus={() => { if (latestMessageRef.current) { latestMessageRef.current.scrollIntoView(); } }}
          disabled={loadingState !== 'ready' || (messages.length === 0 && !canInitiate)}
        />
        <Button
          onClick={sendMessage}
          disabled={newMessage.trim().length === 0 || (messages.length === 0 && !canInitiate)}
        >
          Send
        </Button>
      </div>
    </div>
  );
}